import { InferLogboxSetterParameters, LogboxDelegator, LogboxPropKeys } from './logger/logbox/LogboxDelegator'
import { MixpanelDelegator } from './logger/mixpanel/MixpanelDelegator'
import { TmapLogClientConfig, TmapLogResponseBody } from './types'
import { format as formatDateTime } from 'date-fns/format'

export type LogPropKeys = LogboxPropKeys
export type LogProps = { [key in LogPropKeys]?: Primary }
export type CustomProps = Record<string, Primary | object>
type Primary = string | number | boolean | null | undefined
type SetterParameterType<Key extends LogboxPropKeys> = InferLogboxSetterParameters<Key>

export class TmapLogBuilder {
  private readonly configPromise

  private readonly loggersPromise

  private readonly props: LogProps = {}

  private customs: CustomProps | null = null

  private userProps: CustomProps | null = null

  constructor({ configPromise, loggerPromise }: {
    configPromise: Promise<TmapLogClientConfig>;
    loggerPromise: Promise<{ logbox: LogboxDelegator | null; mixpanel: MixpanelDelegator | null; }>
  }) {
    this.configPromise = configPromise
    this.loggersPromise = loggerPromise
  }

  send() {
    return this.loggersPromise.then(async ({ logbox, mixpanel }) => {
      const { timeout = 2000, onSend } = await this.configPromise

      await this.applyCommonProps()
      await onSend?.(this)

      return new Promise<TmapLogResponseBody>(resolve => {
        Promise.all([
            logbox ? logbox.send(this.props, this.customs, this.userProps) : null,
            mixpanel ? mixpanel.send(this.props, this.customs, this.userProps) : null
          ])
          .then(([logbox, mixpanel]) =>
            resolve({ logbox, mixpanel } as TmapLogResponseBody)
          )

        setTimeout(() => resolve({ logbox: null, mixpanel: null }), timeout)
      })
    })
  }

  set<K extends LogPropKeys>(key: K, value: SetterParameterType<K>[0]) {
    this.props[key] = value
    return this
  }

  get<K extends LogPropKeys>(key: K) {
    return this.props[key]
  }

  setPageId(value: string) {
    return this.set('page_id', value)
  }

  setPageType(value: string) {
    return this.set('page_type', value)
  }

  setActionId(value: string) {
    return this.set('action_id', value)
  }

  setEuk(value: string) {
    return this.set('euk', value)
  }

  setCustom(key: string, value: Primary | object) {
    (this.customs = this.customs || {})[key] = value
    return this
  }

  getCustom<T extends Primary | object>(key: string) {
    return this.customs?.[key] as T | undefined
  }

  setUserProp(key: string, value: Primary) {
    (this.userProps = this.userProps || {})[key] = value
    return this
  }

  getUserProp<T extends Primary>(key: string) {
    return this.userProps?.[key] as T | undefined
  }

  private async applyCommonProps() {
    const config = await this.configPromise
    const { innerWidth, innerHeight, screen } = window
    const { browser, device, os } = await import('ua-parser-js')
      .then(UAParser => UAParser.default(window.navigator.userAgent))

    const commonProps: LogProps = {
      app_version: config.appVersion || '',
      carrier_name: config.carrier || '',
      device_id: config.deviceId || '',
      session_id: config.sessionId || '',
      ip: config.ip || '',
      euk: config.euk || '',
      browser_name: browser.name || '',
      browser_version: browser.version || '',
      device_model: device.model || '',
      manufacturer: device.vendor || '',
      os_name: os.name || '',
      os_version: os.version || '',
      language_code: window.navigator.language || '',
      url: window.location.href,
      referrer: window.document.referrer || '',
      document_title: window.document.title,
      screen_width: String(screen.width),
      screen_height: String(screen.height),
      resolution: `${screen.width}*${screen.height}`,
      network_type: '',
      local_time: formatDateTime(
        new Date((new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000)) + (540 * 60 * 1000)),
        'yyyyMMddHHmmssSSS',
      ),
    }

    const commonCustoms: CustomProps = {
      webview_width: String(innerWidth),
      webview_height: String(innerHeight),
      webview_resolution: `${innerWidth}*${innerHeight}`,
      physical_screen_width: String(screen.width),
      physical_screen_height: String(screen.height),
      physical_screen_resolution: `${screen.width}*${screen.height}`,
    }

    for (const key in commonProps) {
      if (typeof this.props[key as LogPropKeys] === 'undefined') {
        this.props[key as LogPropKeys] = commonProps[key as LogPropKeys]
      }
    }

    for (const key in commonCustoms) {
      if (typeof this.getCustom(key) === 'undefined') {
        this.setCustom(key, commonCustoms[key])
      }
    }
  }
}
