import { UseQueryResult } from '@tanstack/react-query'
import {
  AdsServingResBody,
  GetAdsServingRequestParams,
  RequireAtLeastOne,
  getAdsServing,
} from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'

import { useQueryWrapper } from '@/hooks/useQueryWrapper'

import { KEYS } from '../keys'

interface Props extends GetAdsServingRequestParams {
  useErrorBoundary?: boolean
}

function useGetAdsServing(
  props: RequireAtLeastOne<GetAdsServingRequestParams, 'inventoryCode' | 'inventoryCodes'> & Props
): UseQueryResult<AdsServingResBody, AxiosError> {
  const { inventoryCode, inventoryCodes, useErrorBoundary } = props

  return useQueryWrapper<AdsServingResBody, AxiosError>(
    [KEYS.BANNER_LIST(), inventoryCode],
    async () => {
      const paramsObj = {} as Omit<GetAdsServingRequestParams, 'inventoryCode'>

      const [displayInfo, currentPosition, deviceAdId, isAndroid, vendorId, tmapInfo] =
        await Promise.all([
          TmapApp.getDisplayInfo(),
          TmapApp.getCurrentPosition(),
          TmapApp.getDeviceAdId(),
          TmapApp.env.isAndroid,
          TmapApp.getDeviceServiceVendorId(),
          TmapApp.getTmapInfo(),
        ])

      if (displayInfo) {
        paramsObj.w = displayInfo.screenWidth
        paramsObj.h = displayInfo.screenHeight
      }
      if (currentPosition) {
        paramsObj.lat = currentPosition[0].toString()
        paramsObj.lon = currentPosition[1].toString()
        paramsObj.posTime = `${dayjs().format('YYYYMMDDHHmmss')}`
      }
      if (isAndroid) {
        paramsObj.adid = deviceAdId
      } else {
        paramsObj.idfa = deviceAdId
      }
      if (!isAndroid) {
        paramsObj.idfv = vendorId
      }
      if (tmapInfo) {
        paramsObj.language = tmapInfo.locale
      }

      if (inventoryCode) {
        const { data } = await getAdsServing({ inventoryCode, ...paramsObj })
        return data
      } else if (inventoryCodes) {
        const { data } = await getAdsServing({ inventoryCodes, ...paramsObj })
        return data
      } else {
        return {} as AdsServingResBody
      }
    },
    {
      useErrorBoundary: useErrorBoundary ?? true,
    }
  )
}

export { useGetAdsServing }
