import { TmapLogLogboxConfig, TmapLogType } from '../../types'

export function createLogboxDelegator({
  serviceId, logType, config,
}: {
  serviceId: string; logType: TmapLogType; config: TmapLogLogboxConfig;
}) {
  return import('./LogboxDelegator').then(({ LogboxDelegator }) => {
    return new LogboxDelegator({
      serviceId,
      logType,
      config,
    })
  })
}
