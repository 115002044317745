import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@18.19.66_terser@5.36.0_webpack@5.96.1/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2FglobalTheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3VXu24kNxDM%2FRUEJrGBk0A233TmzIHhxLkxMyT11q529Tb870Zz7mBstRY4XFBVO2Q%2Fqpsqh93uWf3zk1IXF3%2Bb1yPtP3e6qKn3%2FusJaIqatNanILEy9dznU9wWNbXWdbenuGNcN2rpFPdFTUtb%2Fbqe4qGoKa2Zcj7FY1FTaNFFODcVNXnjg4fv5KImG2y29RSfi5ooUibAl6ImE00ycO5a1OFqmX%2FW39T271LTL6eSKiUOJE1KEki6kBhQXEkFnnMtJHjZG6GwoLgVCudBcickART3QhFB8SAUmJFHocig2H1XkPff1P%2F%2FXWq88P6MEFP8dEaHWTyc0WEuj2d0WLfnMzqM4%2BWMDtP%2FekaHRXg7o8NSvJ%2FRYUE%2BeDr4nnGUfPIUCN2IEcODZ12bFgQPnzQv4kuGB5CtaRGEHeMqOEG4QXiqBgieQTo7yvgLHkLGWB1gmBmeQsYT4VQxPIZ667HDGDJ5TNaWO8wVMw%2BiBhyYZhnEbGsDYh1EMCsBUZmYLc0wG00raqpW1%2BSB6EVNc9VLwOteFTXFVSeHAV4XNblVewv7wNzw4blHkfZbJkxbOsZxx81gaxLEPTeDneeOhz8UNeUY1r4A8cjXjbbhNjI7XguN6oqf2nMclfwcgXjivvKmiZQceGOQcQ4LdRwB9kXc6pnT3nvomPYXXnq91YZnvI60116ReOPD61pXvO77aOqFFmyGj0Fklx0Qn5s%2FlgDXJfagJucdEuMBYKhZOIPGI8D1FStI4xXgpQ3IfY9cEOzBlKsoLbEHnV0jGofiiGNeG2SX0iBSQ39Q3iKPCbqE5kE44yG7tAzCRoe%2FGB6MvYpb1dHUPTaMo42mbr1C%2BxB7MFFtMxLsQdvWJcK8IvYg%2BSX5AAR70LTsHKbkdrxqQrPQDMQe1M05wucde1AnWgz%2B4mF7Ii4db%2FW4Ea7irXbb6EszeJD22%2BibEx7%2BNIg14%2FShwyCWqjHA43hezl48U9mDs41JEOzBENwiiNexWKgK4u1H5Hj4%2B0aYijX%2F2CI3Czx66XMLMGHkdnuAz8FCM1gziCze4ZY9WE0MgmAP5uQXDYWy7MGgbRaE3yK34lPhx1aD0lr2YK9taWAcO%2FZgrTN2ux17cJ1dBnPasQdd8A5yZcceJBcJOtGyB9eZVg2zxNZRc3Ia6mHb%2BOPBJI3XZQ96MoR9Za%2BK%2BuuPP3%2F7%2FZs6zo%2FHi2M73MB%2Bs9dFmUt9aA%2BA3zBuJH7LOEn8jnEr8XvGncQfGPcSf2Q8SHzHeJT4nvEk8aei6Ku4Dox%2FcZ9jUU50znNRwpP2pagowNeijNYG4bcBI%2FpeVBbgR1HC5vbzi7OcLkr4xZlxEoBUFLjE2aIuEHNFuf07gL6oIMBQVBJgLMqQQFNRZnzg3%2F8AhzkIwKsPAAA%3D%22%7D"
import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@18.19.66_terser@5.36.0_webpack@5.96.1/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ffeatures%2Fweb%2Fcomponents%2Fuser-profile%2FuserProfile.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA91V3W6bMBS%2B71NYlSol2pwCISlzn2RXkYET4tXYnm0S2qnvPmFMB4SSptpudhVim%2BPv73BWu7B6ekpsEKBfNwgpaZhlUhCkgVPLjvDYrNI8Z6IgKIxUjaJA1c1qzozi9JmgPYf68eb1ZtXVCl2tE8vtgaCM8mwRBsEdwughUvWyebekumACc9hbgsLtZEHkfnHONGQtpkzyqhSDq6IZ2Gf1KGeFwMxCaQjKQFjQ7hopLDbsBQg6Ur3AeBceTaRe5Prn8m3%2FBKw42LMTlTvBmQB88CeiVaKh7LFMpbWyJChRQ5kiQujegnYUMiksCEvQ7e3jgBFNjeSVdYxavfCm1UsqmjH7TFAwKkuzRoJ%2B9TMvvqAw8F50uJv1Qdmw%2BZfS7KnQshI5ziSXeqwAuBqp1DlorGnOKkNQPGaKWFk4ICNJ8FiTdQv3wCxgo2gGBAl50lQ5ZEfQey5PBB1YnoNo1izUFv%2FZAM6ZMsz05NctvTGm2N00561x1CZZJ8tBrY3nVuOJ0Edd6B1Ul0HinByU2M6V2G5did7xh76tcdQG4i2BvkPf%2BrZp27hdmxDlssnRuybPdc%2BPi91jzrun%2Bxh8RPbk6gbytANnh1QEbdrMW02F2UtdkvaRUwsLvFX1V4TD%2BM7B9GJ3AMeAexqykhZAUKX54p4aA9bcuyVzzzK5UyB2J80srMyxWI5ebYVsuFAmRnsaFFDbNIR%2FHIjx7bNi4Ng7%2BZ8JQmdGg4e%2BZxZ7qXr94sdSFF0KOP%2FUeAhGHyJ6tXHOKOxd8zNhPTsTKCEp7KWGa2fNhzrl%2BwJvgmEmklEmkisyoSQT9i9nYWImTtP%2ByKSc3PwHYzOdScbwupmchK3yXbNv%2B6mphAHbYx0PXeumwxClD8Ilhut0OWpH5XiMh9SwORza19%2FQhaOclgoAAA%3D%3D%22%7D"
export var name = '_1ukk8t03';
export var nameBtn = '_1ukk8t02';
export var nameUnregistered = '_1ukk8t04';
export var nameWidthVariants = {verified:'_1ukk8t05',notVerified:'_1ukk8t06'};
export var positionRightVariants = {verified:'_1ukk8t08',notVerified:'_1ukk8t09'};
export var unVerifiedTag = '_1ukk8t07';
export var userInfoWrapper = '_1ukk8t01';
export var userPoint = '_1ukk8t0a';
export var userPointRedDot = '_1ukk8t0b _1ukk8t0a';
export var wrapper = '_1ukk8t00';