import { TmapLogType } from '../../types'
import { Mixpanel } from 'mixpanel-browser'
import { CustomProps, LogProps } from '../../TmapLogBuilder'
import { MixpanelResponseBody } from './types'

export class MixpanelDelegator {

  private readonly logType

  private readonly serviceId

  constructor(private mixpanel: Mixpanel, {
    logType,
    serviceId,
  }: {
    logType: TmapLogType;
    serviceId: string;
  }) {
    this.logType = logType
    this.serviceId = serviceId
  }

  async send(props: LogProps, customs: CustomProps | null, userProps: CustomProps | null) {
    return new Promise<MixpanelResponseBody>((resolve) => {
      const eventName = [
        this.serviceId,
        props.page_id,
        props.action_id,
      ].filter(value => Boolean(value)).join(':')

      if (userProps != null) {
        this.mixpanel.people.set(userProps)
      }

      this.mixpanel.track(eventName, {
        ...props,
        ...customs,
        service_id: this.serviceId,
        log_type: this.logType,
      }, resolve)
    })
  }

}
