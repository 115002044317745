import { MyReviewResponse } from '@tmap-web-lib/remote-api-client/frontman'
import { debounce } from 'lodash-es'
import Image from 'next/image'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import reaction3 from '@/assets/images/face_curios_1.svg'
import reaction2 from '@/assets/images/face_smile_2.svg'
import reaction1 from '@/assets/images/face_wink_3.svg'
import ReviewPromotionBanner from '@/assets/images/img_my_review.svg'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { useOpenService } from '@/hooks'

import * as styles from './placeReview.css'

interface Props {
  data: MyReviewResponse | null
}

function PlaceReview({ data }: Props) {
  const textRef = useRef<HTMLElement | null>(null)
  const openService = useOpenService()
  const { action_id } = MyLogDefinition.my
  const reviewData = data?.data || null
  const [isNarrow, setIsNarrow] = useState(false)
  const reviewTripData = reviewData?.review || null

  const callMoveToReviewHomeDebouncing = useMemo(
    () =>
      debounce(
        (actionEvent: string) => {
          sendEventLog(actionEvent)
          openService('tmap://place?productid=review&pageid=home')
        },
        1000,
        { leading: true, trailing: false }
      ),
    [openService]
  )

  const moveToReviewHome = useCallback(
    (actionEvent: string) => {
      callMoveToReviewHomeDebouncing(actionEvent)
    },
    [callMoveToReviewHomeDebouncing]
  )

  const callMoveToMyReviewDebouncing = useMemo(
    () =>
      debounce(
        () => {
          sendEventLog(action_id?.tapMyReviewReaction)
          openService('tmap://place?productid=review&pageid=home&extra=%7B%22tab%22%3A%22my%22%7D')
        },
        1000,
        { leading: true, trailing: false }
      ),
    [action_id?.tapMyReviewReaction, openService]
  )

  const moveToMyReview = useCallback(() => {
    callMoveToMyReviewDebouncing()
  }, [callMoveToMyReviewDebouncing])

  const callMoveToReview = useMemo(
    () =>
      debounce(
        () => {
          if (reviewTripData?.newReview) {
            sendEventLog(action_id?.tapAvailableReview)
            openService(
              `tmap://place?productid=review&pageid=write&extra=${encodeURIComponent(JSON.stringify({ poiId: reviewTripData?.poiId }))}`
            )
          } else {
            sendEventLog(action_id?.tapWritingReview)
            openService(
              `tmap://place?productid=review&pageid=write&extra=${encodeURIComponent(
                JSON.stringify({
                  poiId: reviewTripData?.poiId,
                  reviewId: reviewTripData?.reviewId,
                })
              )}`
            )
          }
        },
        1000,
        {
          leading: true,
          trailing: false,
        }
      ),
    [openService, reviewTripData, action_id?.tapAvailableReview, action_id?.tapWritingReview]
  )

  const moveToReview = useCallback(() => {
    callMoveToReview()
  }, [callMoveToReview])

  const displayReactions = useMemo(() => {
    if (reviewData && reviewData.reviewCount > 0) {
      const isReactionExist =
        reviewData.reviewReaction1 > 0 ||
        reviewData.reviewReaction2 > 0 ||
        reviewData.reviewReaction3 > 0
      sendEventLog(action_id?.viewMyReviewReaction, { reaction: isReactionExist ? 'y' : 'n' })
      return (
        <button
          className={styles.reactionWrapper}
          data-testid="review_reaction_banner"
          onClick={() => moveToMyReview()}
        >
          내 리뷰 반응
          <div className={styles.reactions}>
            <div>
              <Image
                src={reaction1}
                alt={''}
              />
              <span>{reviewData.reviewReaction1 > 99 ? '99+' : reviewData.reviewReaction1}</span>
            </div>
            <div>
              <Image
                src={reaction2}
                alt={''}
              />
              <span>{reviewData.reviewReaction2 > 99 ? '99+' : reviewData.reviewReaction2}</span>
            </div>
            <div className={styles.lastReaction}>
              <Image
                className={styles.lastReactionImage}
                src={reaction3}
                alt={''}
              />
              <span ref={textRef}>
                {reviewData.reviewReaction3 > 99 ? '99+' : reviewData.reviewReaction3}
              </span>
            </div>
          </div>
        </button>
      )
    }
  }, [reviewData, action_id?.viewMyReviewReaction, moveToMyReview])

  const moreReviewBtn = useMemo(() => {
    if (reviewData) {
      return (
        <button
          data-testid="review_write_btn"
          className={styles.moreReviewBtn}
          onClick={() =>
            action_id?.tapAvailableReviewMore && moveToReviewHome(action_id.tapAvailableReviewMore)
          }
        >
          <>
            작성 가능한&nbsp;<span>{reviewData.tripCount}개</span>&nbsp;리뷰 쓰기
          </>
        </button>
      )
    }
  }, [reviewData, moveToReviewHome, action_id?.tapAvailableReviewMore])

  const displayReviewModule = useMemo(() => {
    if (reviewData) {
      return (
        <>
          <div
            data-testid="review_module"
            className={styles.reviewModuleWrapper}
            onClick={moveToReview}
          >
            <p>{reviewTripData?.poiName}</p>
            <p>
              {reviewTripData?.newReview
                ? '방문 경험을 공유해 주세요'
                : '리뷰 작성을 완료해 주세요'}
            </p>
            {reviewTripData?.tripDateTime.length && (
              <div className={styles.tripDateTag}>{reviewTripData.tripDateTime} 주행</div>
            )}
            <button className={styles.reviewBtn}>{reviewTripData?.reviewGuide}</button>
          </div>
          {reviewData.tripCount >= 2 && moreReviewBtn}
        </>
      )
    }
  }, [reviewTripData, reviewData, moreReviewBtn, moveToReview])

  const reviewDefaultBanner = useMemo(() => {
    return (
      <button
        data-testid="default_review_banner"
        className={styles.banner}
        onClick={() => action_id?.tapReviewBanner && moveToReviewHome(action_id.tapReviewBanner)}
      >
        <Image
          style={{ width: '100%' }}
          src={ReviewPromotionBanner}
          alt={'리뷰 기본 배너'}
        />
      </button>
    )
  }, [moveToReviewHome, action_id?.tapReviewBanner])

  const displayReview = useMemo(() => {
    if (reviewData) {
      if (!reviewData.reviewCount && !reviewData.tripCount) {
        return reviewDefaultBanner
      } else {
        return (
          <>
            {reviewData.reviewCount > 0 && displayReactions}
            {reviewData.review && displayReviewModule}
          </>
        )
      }
    }
  }, [reviewData, displayReactions, displayReviewModule, reviewDefaultBanner])

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth <= 320)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (textRef.current) {
      if (isNarrow) {
        textRef.current.textContent = '...'
      } else {
        if (reviewData && reviewData.reviewReaction3 > 99) {
          textRef.current.textContent = '99+'
        } else {
          textRef.current.textContent = reviewData && reviewData.reviewReaction3.toString()
        }
      }
    }
  }, [isNarrow, reviewData])

  useEffect(() => {
    if (reviewData && !reviewData.reviewCount && !reviewData.tripCount) {
      sendEventLog(action_id?.viewReviewBanner)
    }
  }, [reviewData, action_id?.viewReviewBanner])

  useEffect(() => {
    if (reviewTripData) {
      if (reviewTripData.newReview) {
        sendEventLog(action_id?.viewAvailableReview)
      } else {
        sendEventLog(action_id?.viewWritingReview)
      }
    }
  }, [reviewTripData, action_id?.viewAvailableReview, action_id?.viewWritingReview])

  useEffect(() => {
    if (reviewData && reviewData.tripCount >= 2) {
      sendEventLog(action_id?.viewAvailableReviewMore)
    }
  }, [reviewData, action_id?.viewAvailableReviewMore])

  if (reviewData && !reviewData.needPopup) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p>
          장소 리뷰
          {reviewData && reviewData.reviewCount > 0 && (
            <span
              data-testid="review_count"
              className={styles.count}
            >
              {reviewData.reviewCount}
            </span>
          )}
        </p>
        <button
          data-testid="review_more_btn"
          className={styles.btn_more_wrapper}
          onClick={() => action_id?.tapReview && moveToReviewHome(action_id.tapReview)}
        >
          <p className={styles.btn_more}>더보기 </p>
        </button>
      </div>
      {data?.code !== 200 ? reviewDefaultBanner : displayReview}
    </div>
  )
}

export { PlaceReview }
