import { useEffect, useState } from 'react'

import { startMockServer } from '../mockServer/run'

const isTestMode = process.env.NEXT_PUBLIC_MODE === 'test'

/**
 * Mock server 초기화 Hook
 */
function useMockInitHook() {
  const [isMockServerStarted, setMockServerStarted] = useState(!isTestMode)

  // Mock server 초기화
  useEffect(() => {
    if (isTestMode) {
      // mock 서버 시작 함수
      const startMockServerAsync = async () => {
        await startMockServer()
        setMockServerStarted(true)
      }

      startMockServerAsync()
    }
  }, [isMockServerStarted])

  return {
    isEnabled: isMockServerStarted,
  }
}

export default useMockInitHook
