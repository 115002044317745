import { TmapLogBuilder } from './TmapLogBuilder'
import { TmapLogClientConfig, TmapLogType } from './types'
import { createMixpanelDelegator, loadMixpanel } from './logger/mixpanel'
import { createLogboxDelegator } from './logger/logbox'
import { Mixpanel } from 'mixpanel-browser'

let resolveConfig: (config: TmapLogClientConfig) => void
const configPromise: Promise<TmapLogClientConfig> = new Promise((resolve) => {
  resolveConfig = resolve
})

function init(tmapLogConfig: TmapLogClientConfig | (() => Promise<TmapLogClientConfig> | TmapLogClientConfig)) {
  Promise
    .resolve(typeof tmapLogConfig === 'function' ? tmapLogConfig() : tmapLogConfig)
    .then(config => resolveConfig(config))
}

function on(name: 'mixpanel'): Promise<Mixpanel>
function on(name: string): any {
  if (name === 'mixpanel') {
    return configPromise.then(config => loadMixpanel(config.mixpanel))
  }
}

async function createLoggers(logType: TmapLogType) {
  return configPromise.then(async ({
    serviceId,
    logbox: logBoxConfig,
    mixpanel: mixpanelConfig,
  }) => {
    let mixpanel = null
    let logbox = null
    if (logBoxConfig?.token) {
      logbox = await createLogboxDelegator({
        serviceId,
        logType,
        config: logBoxConfig,
      })
    }

    if (mixpanelConfig?.token) {
      mixpanel = await createMixpanelDelegator({
        serviceId,
        logType,
        config: mixpanelConfig,
      })
    }

    return {
      logbox,
      mixpanel,
    }
  })
}

function TmapLog(type: TmapLogType) {
  return new TmapLogBuilder({
    configPromise,
    loggerPromise: createLoggers(type),
  })
}

TmapLog.on = on

TmapLog.init = init

Object.freeze(TmapLog)

export { TmapLog }
