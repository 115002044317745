const isServer = typeof window === 'undefined'

/**
 * mock 서버 실행 함수
 */
function startMockServer() {
  if (isServer) {
    ;(async () => {
      const { server } = await import('./server')

      server.listen()
    })()
  } else {
    ;(async () => {
      const { worker } = await import('./browser')

      worker.start()
    })()
  }
}

export { startMockServer }
