import { TmapLogMixpanelConfig } from '../../types'

let inited = false

export function loadMixpanel(config?: TmapLogMixpanelConfig) {
  return import('mixpanel-browser').then(({ default: mixpanel }) => {
    if (!inited && config?.token) {
      inited = true
      const { token, identity, ...mixpanelConfig } = config
      mixpanel.init(token, {
        ...mixpanelConfig,
        loaded: () => {
          mixpanelConfig.loaded?.(mixpanel)
          if (identity) {
            mixpanel.identify(identity)
          }
        }
      })
    }
    return mixpanel
  })
}
